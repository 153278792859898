import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import UsersGrid from '../Grid/UsersGrid';
// import Button from '../Input/Button';
import NewClient from '../PopUp/NewClient';
import LoadingIndicator from '../Layout/LoadingIndicator';
import { postClient, postCampaign, getUsers } from '../../api_requests';
import { SET_USERS } from '../../store/action';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
//import { deleteCreative } from '../../api_requests';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '2.5vw 0',
        gap: '2vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'normal',
        alignItems: 'center',
    },
    action_bar: {
        width: '90%',
        display: 'flex',
        boxSizing: 'content-box',
        gap: '1vw',
        alignItems: 'center',
    },
    title: {
        fontWeight: 'bold', 
        fontSize: '1.8rem', 
        fontFamily: 'Lexend',
    },
}));

export default function CreativeManagementPage(props) {
    const classes = useStyles();

    const mountRef = React.useRef(false);
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.user.token);
    const [clientPoPupState, setClientPoPupState] = useState(false);
    const [requestingData, setRequestingData] = useState(false);
    const [clientName, setClientName] = useState('');
    const [apiData, setApiData] = useState(false);
    const [gridData, setGridData] = useState([]);

    const handleEdit = (id) => { };

    const handleDelete = (id, callback) => { };

    const handleUserAdd = (campaign) => {
        setRequestingData(true);
        postCampaign(authToken, campaign, addCampaignCallback);
    };

    const addCampaignCallback = (response, campaign) => {
        if (response.error !== 0) {
            toast.error('Error: ' + response.message);
            return;
        }
        setRequestingData(false);
        setApiData(false);
        getUsers(authToken, requestUsers);
    };

    const handleClientPopUpDiscard = () => {
        setClientName('');
        setClientPoPupState(false);
    };

    const handleClientPopUpConfirm = (data) => {
        setRequestingData(true);
        postClient(authToken, data, addClientCallback);
    };

    const addClientCallback = (response, client) => {
        if (response.error !== 0) {
            toast.error('Error: ' + response.message);
            return;
        }
        let newRows = [...gridData];
        newRows.push({
            id: response.result,
            clientId: response.result,
            clientName: client.name,
            campaigns: []
        });
        setGridData(newRows);
        setRequestingData(false);
        setClientPoPupState(false);
    };

    const handleGridData = useCallback((users) => {
        let gridRows = [];
        for (let i = 0; i < users.length; i++) {
            let user = users[i];
            gridRows.push({
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                isActive: user.isActive,
                created: user.created,
                roles: user.roles.join(', '),
            });
        }
        setGridData(gridRows);
        setApiData(true);
    }, []);

    const requestUsers = useCallback((data) => {
        if (data.error !== 0) {
            toast.error('Error: ' + data.message);
            return;
        }
        dispatch({ type: SET_USERS, users: data.users });
        handleGridData(data.users);
    }, [dispatch, authToken, handleGridData]);

    useEffect(() => {
        if (!mountRef.current) {
            getUsers(authToken, requestUsers);
            mountRef.current = true;
        }
    }, [authToken, requestUsers]);

    return (
        <div className={`page-container ${classes.container}`} >
            <div className={classes.action_bar}>
                <Typography className={classes.title}>
                    User Management
                </Typography>
            </div>
            <UsersGrid
                rows={gridData}
                apiData={apiData}
                addUser={handleUserAdd}
                editCreative={handleEdit}
                deleteCreative={handleDelete} />
            {requestingData ? <LoadingIndicator /> : null}
            {clientPoPupState ? <NewClient name={clientName} discardCallback={handleClientPopUpDiscard} confirmCallback={handleClientPopUpConfirm} /> : null}
        </div>
    )
}